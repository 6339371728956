import React from 'react';
import Layout from '../components/Layout';
import CostSupport, {
  costAndSupportBody
} from '../components/CostSupport/CostSupport';
import Hero2, { costAndSupport } from '../components/Hero/Hero2';
import TwoColor, {
  costAndSupportTwoColor
} from '../components/TwoColor/TwoColor';
import GlobalCostAndSavings from '../components/GlobalCostAndSavings/global-cost-and-savings';

const Home = () => {
  return (
    <Layout section="main" page="/cost-and-support/">
      <>
        {/* <Hero2 {...costAndSupport} /> */}
        {/* <TwoColor {...costAndSupportTwoColor} /> */}

        {/* {costAndSupportBody.map((costAndSupportProps, index) => (
          <CostSupport {...costAndSupportProps} key={index} />
        ))} */}

        {/* <section
          className="bg-sage-010 px-6 py-5 md:py-10"
          id="terms-and-conditions"
        >
          <div className="container mx-auto">
            <div className=" text-sm3 leading-[1.125rem] md:mx-24">
              <p className="-indent-1 md:mb-5">
                <sup className="text-[0.75rem]">†</sup>
                Myfembree® Copay Assistance Program: Terms and Conditions: The
                Myfembree Copay Assistance Program (“Copay Program”) is for
                eligible patients with commercial prescription insurance for
                Myfembree. With this Copay Program, eligible patients may pay as
                little as $5 per monthly Myfembree prescription (or $15 for a
                90-day prescription); subject to calendar year maximum on Copay
                Program assistance of $5000 per calendar year. After the
                calendar year maximum for Copay Program assistance is reached,
                patient will be responsible for the remaining out-of-pocket
                costs for Myfembree. This Copay Program may not be redeemed more
                than once every 21 days. The Copay Program is not valid for
                patients whose prescription claims are reimbursed, in whole or
                in part, by any state or federal government program, including,
                but not limited to, Medicaid, Medicare, Medigap, Department of
                Defense (DoD), Veterans Affairs (VA), TRICARE, Puerto Rico
                Government Insurance, or any state patient or pharmaceutical
                assistance program. Offer is not valid for cash-paying patients.
                Patient must be a resident of the U.S., Puerto Rico, or U.S.
                Territories. This Copay Program is void where prohibited by
                state law and on the date an AB rated generic equivalent for
                Myfembree becomes available. Certain rules and restrictions
                apply. This offer is not insurance. This offer cannot be
                combined with any other coupon, free trial, discount,
                prescription savings card, or other offer. This offer is not
                conditioned on any past or future purchase, including refills.
                Patient and participating pharmacists agree not to seek
                reimbursement for all, or any part of the benefit received by
                the patient through this Copay Program. Patient and
                participating pharmacists agree to report the receipt of Copay
                Program benefits to any insurer or other third party who pays
                for or reimburses any part of the prescription filled using the
                Copay Program, as may be required by such insurer or third
                party. Myovant Sciences reserves the right to revoke, rescind,
                or amend this offer without notice.
              </p>
            </div>
          </div>
        </section> */}

        <GlobalCostAndSavings />
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL = 'https://www.myfembree.com/cost-and-support/';
  const title =
    'MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) | Cost & Support';
  const pageTitle =
    'MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) | Cost & Support';
  const description =
    "Discover the MYFEMBREE® Copay Assistance Program and see if you're eligible to save on your prescription. Terms & Conditions apply.";
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
    </>
  );
};

export default Home;
